$text: #FBFBFE;
$background: #0E0E1B;
$accent: #FB7375;
$button-primary: #4242CD;
$button-secondary: #1C1C46;

html,body {overflow-y: scroll; overflow-x: hidden }
#root {
  overflow-x: hidden;
}

h1 {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 180px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h2 {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h3 {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 67px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h4 {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h5 {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h6 {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

p {
  color: $text;
  font-family: "Metropolis", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.p2 {
  color: $text;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0
}

.p3 {
  color: $text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0
}

body {
  background-color: $background;
}

#background-gradient {
  position: absolute;
  top: 0;
  right: -20vw;
  width: 100%;
  z-index: -2;
}

#background-noise-3 {
  position: absolute;
  top: 2264px;
  right: 0;
  z-index: -1;
  width: 100%;
}

#background-noise-2 {
  position: absolute;
  top: 1132px;
  right: 0;
  z-index: -1;
  width: 100%;
}

#background-noise {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 100%;
}

a {
  text-decoration: inherit;
  color: inherit;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

#header-logo {
  margin: 32px 0 0 32px;
}

#header-tabs {
  margin: 32px 32px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}

#signature {
  width: 70vw;
  position: absolute;
  right: 10vw;
  bottom: 15vh;
}

.hero-section {
  position: relative;
  display: flex;
  padding: 0 0 64px 64px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
  bottom: 0;
  min-height: calc(100vh - 95px - 64px);
}

.intro {
  overflow: hidden;

  h1 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: flex-start;
    gap: 64px;
  }
}

.left-hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.right-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.text-container {
  overflow: hidden;
}

.infinite-scroll {
  animation: scroll 15s linear infinite;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
  }

  h3 {
    flex-shrink: 0;
    margin: 0;
  }

  h5 {
    padding-bottom: 4px;
    margin: 0;
  }
}

#projects-scroll {
  --gap: 50px
}

@keyframes scroll {
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.projects {
  margin: 64px 0 64px 0;
  position: relative;
  padding: 48px 0 48px 0;
  max-width: 1440px;
}

.projects-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.projects-indiv {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 70%;

  hr {
    width: 100%;
    color: $text;
  }
}

.projects-info {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;

  h5 {
    font-weight: 400;
    margin: 0
  }

  p {
    font-size: 12px;
  }
}

.projects-attributes {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;

  p {
    margin: 0;
  }
}

#bot-left {
  bottom: 0;
  left: 10%;
  position: absolute;
}

#bot-right {
  bottom: 0;
  right: 10%;
  position: absolute;
}

#top-left {
  top: 0;
  left: 10%;
  position: absolute;
}

#top-right {
  top: 0;
  right: 10%;
  position: absolute;
}

.hovered_img {
  position: absolute;
  height: 100px;
}

.projects-titlebox {
  overflow: hidden;
  position: relative;
}

#work-exp-scroll {
  gap: 500px;
  --gap: 250px;
}

.jobs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin: 64px 0 64px 0;
  position: relative;
  max-width: 1440px;
}

.jobs-indiv {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  justify-content: center;
}

.job-date {
  display: flex;
  padding: 3px 0;
  justify-content: center;
  align-items: center;
  width: 11%
}

.job-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 65%;
}

.job-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  p {
    margin: 0
  }
}

.jobs:hover .jobs-indiv {
  opacity: 0.5;
  transition: opacity 0.5s;
}

.jobs .jobs-indiv:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

#contact-me-scroll {
  gap: 400px;
  --gap: 358px;
}

.contact-me {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin: 64px 0 64px 0;
  position: relative;
  max-width: 1440px;

  svg {
    width: 30px
  }

  p {
    margin: 0
  }
}

.contact-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  justify-content: center;

  a {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-self: stretch;
    align-items: center;
  }
}

.contact-right {
  width: 20%;

  p {
    text-align: right;
  }
}

.about-me {
  display: flex;
  flex-direction: row;
  gap: 80px;
  align-items: center;
  margin: 100px;
  max-width: 1920px;
}

.about-me {
  display: flex;
}

#about-blob {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.case-study {
  display: flex;
  margin: 64px 128px 64px 128px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 1920px;
}

.case-study-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  h4 {
    margin: 0;
  }
}

.case-study-section {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p:not(.p2) {
    font-weight: 700;
    margin: 0;
  }

  .p2 {
    line-height: 1.125;
  }

  u {
    font-weight: 500;
    line-height: 1.25;
  }

  ol {
    margin: 0;
  }
}

.case-study-quote p {
  font-weight: 700;
  font-style: italic;
  margin: 2rem;
}

.case-study-images {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  img {
    width: 49%;
  }
}

.case-study-comparisons {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  ul {
    margin: 0;
  }
}


.case-study-comparisons-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.case-study-comparisons-images {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 50%;
  padding-top: 1.25em;
  img {
    width: 49%;
  }
}

.case-study-section-comparisons {
  gap: 16px;
}

#map-app  {
  .case-study-comparisons {
    align-items: center;
  }

  .case-study-comparisons-images {
    width: auto;
    justify-content: center;
  }

  .case-study-comparisons-text p {
    line-height: 1.25;
  }
}

@media (max-width: 768px) {
  .hero-section {
    .intro {
      width: 80vw;

      div {
        gap: 16px;
      }
    }
  }
  p {
    font-size: 16px;
  }

  .p2 {
    font-size: 12px;
  }

  h1 {
    font-size: 90px;
  }

  h5 {
    font-size: 28px;
  }

  h6 {
    font-size: 21px;
  }

  .about-me {
    flex-direction: column-reverse;
    gap: 16px;
    margin: 0 32px 0 32px;

    img {
      width: 95%;
    }
  }

  .about-text h6 {
    margin-bottom: 4px;
  }

  .case-study {
    margin: 0 32px 0 32px;
  }

  .case-study-comparisons {
    flex-direction: column-reverse;
  }

  .case-study-comparisons-images {
    width: 100%;
  }
}
